.pieChartMainWrapper{
    margin: 20px 0px;

}
.PieChartWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 2rem; */
    padding: 5px 20px;
}
.legentWrapper{
    margin-left: 20px;
    min-width: 260px;
}
.legenSubWrapper{
    display: flex;
    gap: 10px;
    align-items: baseline;
}
.legentTitle{
    font-size: 10px;
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 10px;
    

}
.pieChartLegenColor{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 4px;
    margin-left: 15px;
}