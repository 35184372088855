.NotFoundPageWrapper{
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}
.NotFoundContentWrapper{
    text-align: center;
    display: flex;
    gap: 8px;
    flex-direction: column;
}