.moreIconWrapper{
    min-width: 50px;
    align-items: center;
    display: flex;

}
.menuItemContainer{
    display: flex;
    flex-direction: column;
}
.logoutButtonWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}
.logOutIconContainer{
    display: flex;
    justify-content: center;
}