.circulaProgressBarContainer {
    display: flex;
    height: 90vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.previousVisitsContainer {
    display: flex;
    justify-content: space-around;
}

.accordianSummeryClass {
    display: flex;
    justify-content: space-between;
}

/* .vistDateContainer{
    display: flex;
    justify-content: space-between;
    width: 50%;
} */
.DateCase {
    display: flex;
    color: black;
    font-size: 0.9rem;
}

.visitDate {
    text-decoration: underline;
    color: #715d9c;
    cursor: pointer;
    padding-left: 4px;
}

.accordinSummeryWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.sutdyDetailContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 50px;
}

.studyDetailText {
    font-weight: 600;
    margin: 6px 0px;
}

.TextBold {
    font-weight: bold;
    color: black;
}

.accordianDetailContainer {
    display: flex;
    width: 100%;
}

.pieChartContainer {
    width: 50%;
    display: flex;
    justify-content: center
}

.patientNameWrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 8px;
}
.borderBoxShadow{
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) ;
    border-radius: 4px;
}