.App {
    // background-color: #89cff0;
    min-height: 100vh;
}

.app__sidebar {
    padding: 1px;
}

.app__content {
    width: 100%;
    background-color: white;
    padding: 75px 30px 10px;
}
.app__contentMobile {
    width: 100%;
    background-color: white;
    height: calc(100% - 100px);
    padding: 60px 0px;
}
span {
    color: #111211;
    font-weight: bold;
    justify-content: center;
}

a[href] {
    color: #715d9c;
}

table,
td,
th {
    border: 1px solid;
}
.tss-1fbujeu-MUIDataTableHeadCell-toolButton{
    padding: 0 !important;
    margin: 0 !important;
}
.MuiBottomNavigationAction-label {
    opacity: 1 !important;
}

@media (max-width: 600px) {

    .MuiContainer-root .MuiPaper-root {
        padding-bottom: 80px;
    }
    table
    tbody tr td
    {
        word-wrap: break-word;
    
    }
}

.signinlogo{
    width: 60px;
    height: 60px;
}

.demo-editor span {
    font-weight: unset;
}