.CPTHeading{
    padding-left: 0.5rem;
    padding-top: 1rem, 
}
.subHeading{
    margin: 0.5rem;
}
.checkboxWrapper{
    display: flex;
    flex-direction: column;
}
.checkBoxMainWrapper{
    display: flex;
    justify-content: space-between;
}