.selectedMainWrapper{
    min-width: 275px;
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap; 
    margin-bottom: 20px;
}
.selectedPatientText{
    font-weight:700;
    color:#000;
}
.patientsText{
    color: #000;
    font-weight:400;
    margin-right: 15px;
}