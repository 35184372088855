.ContactUsTextWrapper{
    display: flex;
    justify-content: center;
    margin: 10px 0px 20px 0px
}
.borderBox{
    border: 1px solid rgba(0,0,0, 0.12);
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    margin: 24px 0px;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 16px;
}