.landingPageWrapper{
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ButtonWrapper{
    display:flex;
    justify-content:space-between;
    gap: 20px;
    margin: 20px;
    position: absolute;
    top: 120px;
    right: 0;
}