.fileUploadMainWraper{
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.fileUploadWraper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 16px 0px !important; */
    width: 100%;
    gap: 10px;
}
.progressBarWrapper{
    width:30%;
}
.fileNameWrapper{
    display: flex;
    gap: 10px;
}
@media (max-width: 800px) {
    .progressBarWrapper {
        width: 80%;
    }
}