.TitleText{
    /* margin-bottom: 1rem; */
}
.textFieldWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}
.documentcheckboxWrapper{
    display: flex;
    justify-content: space-between;
}
.selectGroupAndStageWrapper{
    display: flex;
    justify-content: space-between;
}
.documentWrapper{
    margin: 16px 0px;
}