.App {
    display: flex;
}

nav {
    background: #fff;
    padding: 50px 0;
    box-shadow: 0px 5px 0px #dedede;
}
nav ul {
    list-style: none;
    text-align: center;
}
nav ul li {
    display: inline-block;
}
nav ul li {
    > span,
    a {
        display: block;
        padding: 5px;
        text-decoration: none;

        font-weight: 800;
        text-transform: uppercase;
        margin: 0 10px;
        cursor: pointer;
    }
    a {
        color: #aaa;
    }
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
    transition: all 0.5s;
}
nav ul li a:hover {
    color: #555;
}

/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
    position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
}
nav.stroke ul li a:hover:after {
    width: 100%;
}

nav.fill ul li a {
    transition: all 2s;
}

nav.fill ul li a:after {
    text-align: left;
    content: '.';
    margin: 0;
    opacity: 0;
}
nav.fill ul li a:hover {
    color: #fff;
    z-index: 1;
}
nav.fill ul li a:hover:after {
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
}

/* Circle */
nav.circle ul li a {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
nav.circle ul li a:after {
    display: block;
    position: absolute;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '.';
    color: transparent;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background: transparent;
}
nav.circle ul li a:hover:after {
    -webkit-animation: circle 1.5s ease-in forwards;
}

/* SHIFT */
nav.shift ul li a {
    position: relative;
    z-index: 1;
}
nav.shift ul li a:hover {
    color: #91640f;
}
nav.shift ul li a:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    content: '.';
    color: transparent;
    background: #f1c40f;
    visibility: none;
    opacity: 0;
    z-index: -1;
}
nav.shift ul li a:hover:after {
    opacity: 1;
    visibility: visible;
    height: 100%;
}

/* Keyframes */
@-webkit-keyframes fill {
    0% {
        width: 0%;
        height: 1px;
    }
    50% {
        width: 100%;
        height: 1px;
    }
    100% {
        width: 100%;
        height: 100%;
        background: #333;
    }
}
