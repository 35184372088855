.serveyAccordianContainer{
        display: flex;
        gap: 10px;

}
.BMITextFIeldCOntainer{
    display: flex;
    justify-content: space-between;
}
.surveyAccordian{
    background:rgba(0, 0, 0, 0.04);
}