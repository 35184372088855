
.circulaProgressBarContainer {
    display: flex;
    height: 90vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.previousVisitsContainer{
    display: flex;
    justify-content: space-around;
}
.accordianSummeryClass{
    display: flex;
    justify-content: space-between;
}
.vistDateContainer{
    display: flex;
    gap: 2px;
}
.DateCase{
    display: flex;
    color: black;
    font-size: 0.9rem;
}
.visitDate{
    text-decoration: underline;
    color: #715d9c;
    cursor: pointer;
    padding-left: 4px;
}
.accordinSummeryWrapper{
    display: flex; justify-content: space-between; width: 100%;
    margin-right: 10px;
}
.sutdyDetailContainer{
    display: flex;
    flex-wrap: wrap;
    margin : 5px 50px;
}
.studyDetailText{
    font-weight: 600;
    margin: 6px 0px;
}

.TextBold{
    font-weight: bold;
    color: black;
}
.accordianDetailContainer{
    display: flex;
    width: 100%;
}
.pieChartContainer{
    width: 50%;
    display: flex;
    justify-content: center
}
.patientNameWrapper{
    display: flex;
    gap: 15px;
    margin-bottom: 8px;
}