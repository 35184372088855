.mainHeadingWrapper{

    display: flex;
    justify-content: center;
}
.borderBox{
    border: 1px solid rgba(0,0,0, 0.12);
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dateWrapper{
    display: flex;
    justify-content: right;
    position: absolute;
    right: 40px;
    /* margin: 10px 0px; */
}