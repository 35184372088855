.menuContainer {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
    cursor: pointer;
}

.leftArroeContainer {
    display: flex;
    justify-content: start;
    padding: 20px 20px 10px 16px;
    cursor: pointer;
}

.listContainer {
    display: flex !important;
    justify-content: center !important;
}

.IconItem {
    min-width: 22px !important;
    margin: 9px 0px
}
.patientPortalContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}